import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
import PortraitsTwo from '../../components/sub/portraitx2.js';
import Image from '../../components/image';
export const frontmatter = {
  title: "The How-To Behind Creating Cult Brands",
  date: "2017-11-02",
  author: "Lauren Lyons",
  authorTitle: "Content Editor, The Doneger Group",
  teaserImage: "../../../static/media/20171102-0.jpg",
  path: "2017-11-02-Creating-Cult-Brands",
  teaserText: "From brand to brand, themes of authenticity, transparency, inclusivity and creative storytelling were prevalent, as consumer expectations continue to shape the industry."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="The How-To Behind Creating Cult Brands" description="From brand to brand, themes of authenticity, transparency, inclusivity and creative storytelling were prevalent, as consumer expectations continue to shape the industry." author='Lauren Lyons' keywords={['Robots']} />
      <ImageLandscape imageSrc="20171102-0.jpg" />
      <MDXTag name="p" components={components}>{`At last week’s Fast Company Innovation Festival, we listened in as some of today’s top disruptors weighed in on what makes a brand cult-worthy, as well as how leading entrepreneurs are changing the way we look at product. From brand to brand, themes of authenticity, transparency, inclusivity and creative storytelling were prevalent, as consumer expectations continue to shape the industry. Here, we dive into how brands can rethink their strategies and engage in the disruptive ideas leading the industry.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Shaking Up Their Industries`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Democratizing Beauty`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`By inserting elements of democratization into the beauty industry, Glossier founder Emily Weiss was able to change the narrative from brands telling a customer what to purchase when, to encouraging women to become their own beauty expert.`}</MDXTag>
      <ImageLandscape imageSrc="20171102-1.jpg" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Delivering Inclusive Experiences`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`For the conception of Bevel, founder Tristan Walker noticed a gaping hole in the men’s grooming industry. With that opening in mind, Walker set out to offer consumers a product that was lacking, and did it in a way that emphasized inclusivity.`}</MDXTag>
      <ImageLandscape imageSrc="20171102-2.jpg" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Creating a Playful Narrative`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`For a company that specializes in a single product, Away has done for luggage what others have done in beauty, music and clothes. By giving this brand a personality, the suitcase company has successfully started conversations around travel and experience while offering a product that makes travel more seamless.`}</MDXTag>
      <ImageLandscape imageSrc="20171102-3.jpg" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`The Playbook to Building a Cult Brand`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Tell Unique Stories`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`All three panelists cited the importance of creating stories around the product that you’re making. There is an endless supply of choice and it’s completely in the hands of the consumer whose story they want to listen to.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Peer-to-Peer`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`For Glossier, peer-to-peer recommendations have impacted sales dramatically. Seeing their customers as co-creators, the beauty brand has taken note of just how influential consumer reviews on Makeup Alley, Sephora and Instagram can be. Glossier has been able to organically grow a representative group of women who create content on their blog Into the Gloss, and are now known for an authentic voice and product.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Make Stores Destinations`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Launching a showroom has allowed Away to curate the full travel shopping experience. They’ve created a space that offers shoppers a cafe with travel books to browse and is also stocked with Millennial-minded travel gear.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Use Brand Extensions That Make Sense`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Following the success of its coffee table books, Away launched a print magazine as a way for them to provide context around their luggage. Essentially a branded travel guide, the company is hoping to resonate in an unexpected way for consumers.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Create An Authentic Voice`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Authenticity has to be believable — a sentiment that is especially relevant in today’s socially-minded climate. All three speakers advised the audience on the importance of leaning into, rather than shying away from, topics that are directly affecting their customers.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Parting Thoughts`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Retail today is simultaneously existing in the past, present and future. Slowly but surely, retail is graduating from traditional store formats to interactive spaces and mobile-friendly shopping destinations. Payment systems are becoming seamless as Apple Pay and Amazon Go eliminate hassle, while fitting rooms from those like Reformation and Marie Claire are also powered by technology.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Looking to the near future, how can your brand make the shopping process simpler for today’s consumer? While people still love going to physical places, retailers have to start thinking about stores in a different context. Look to those like Apple, whose physical spaces were created with the consumer who isn’t going to buy anything that day in mind. Going forward, retail is going to focus on being either hyper-convenient or hyper-experiential.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    